// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  drawer: null,
  drawerImage: true,
  mini: false,
  items: [
    {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      to: '/dashboard',
    },
    {
      title: 'Learners',
      icon: 'mdi-account-box-multiple',
      to: '/learners/',
    },    
    {
      title: 'Assessment',
      icon: 'mdi-folder-edit',
      to: '/assessmentlist/',
    },
    {
      title: 'Assessment Tools',
      icon: 'mdi-format-list-text',
      to: '/AssessmentTools/',
    },
    {
      title: 'Programs',
      icon: 'mdi-calendar-outline',
      to: '/program/',
    },
    {
      title: 'Schools',
      icon: 'mdi-town-hall',
      to: '/school/',
    },
    {
      title: 'Barangays',
      icon: 'mdi-home-map-marker',
      to: '/brgy/',
    },

    // {
    //   title: 'Profile',
    //   icon: 'mdi-account',
    //   to: '/components/profile/',
    // },
    // {
    //   title: 'Typography',
    //   icon: 'mdi-format-font',
    //   to: '/components/typography/',
    // },
    // {
    //   title: 'Icons',
    //   icon: 'mdi-chart-bubble',
    //   to: '/components/icons/',
    // },
    // {
    //   title: 'Google Maps',
    //   icon: 'mdi-map-marker',
    //   to: '/maps/google/',
    // },
    // {
    //   title: 'Notifications',
    //   icon: 'mdi-bell',
    //   to: '/components/notifications/',
    // },
  ],
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
