// Pathify
import axios from "axios";
import { make } from "vuex-pathify";

const state = {
  program: [],
  programDialog:false,
};

const mutations = make.mutations(state);

const actions={
  async loadData({state}){
     await axios.get('api/program').then(res=>{
      state.program=res.data
    }).catch(err=>{console.log(err)})
  },

  async addProgram({dispatch},payload){
    await axios.post('api/program',payload).then(res=>{
     dispatch('loadData')
   }).catch(err=>{console.log(err)})
  },

  async deleteProgram({dispatch},payload){
    await axios.post('api/deleteprogram',payload).then(res=>{
     dispatch('loadData')
   }).catch(err=>{console.log(err)})
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
