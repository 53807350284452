// Pathify
import axios from 'axios'
import { make } from 'vuex-pathify'

const state = { 
    el:'1',
    updateStatus:false,
    apimessage:"",
    newassessment:true,
    assessmentlist:[     
    ],
    assessment:[
    ],
    assess_tools:[ ],
    assess_status:[
        "POST_TEST","PRE_TEST"
    ],
    pretestAssessment:{
        program_id:'',
        school_id:'',
        assessment_tool:'',
        _date:'',
        created_by:'',
        updated_by:''
    },
    pretestLearners:[],
    approvePreTest:{
        assessment_id:'',
        _status:'PRE TEST',
        approved:'',
        users_id:'',
    },
    assessmentStatus:'',
    postAssessmentDialog:false,
    postLearnerItem:{},
    postLearnerIndex:'',
    assessmentSummary:{},
    assesstoollevel:'',
}
const actions = { 

    //new assessment
    async savePreTestAssessment({state,dispatch}){
      //save pretest assessment & selected learners
      await axios.post('api/assessment',state.pretestAssessment).then(res=>{
            dispatch('savePreTestLearner',res.data.id)
      }).catch(err=>{
        console.log(err)
      })
        
    },

    async savePreTestLearner({state},payload){

        await axios.put('api/learnersassessment/'+payload,state.pretestLearners).catch(err=>{
          console.log(err)
        })
          
    },

    // end new assessment


    //update pretestAssessment
    async updatePreTestAssessment({state}){
        
        await axios.put('api/assessment/'+state.pretestAssessment.id,state.pretestAssessment).then(res=>{
            // state.pretestAssessment=res.data
            state.updateStatus=true;
            state.apimessage="Update Assessment Success"
        }).catch(err=>{
            console.log(err)
            state.updateStatus=false;
            state.apimessage="Update Assessment Failed"
        })
    },

    async updatePreTestLearners({state}){
        await axios.put('api/learnersassessment/'+state.pretestAssessment.id,state.pretestLearners).then(res=>{
            state.updateStatus=true;
            state.apimessage="Update Learners Successfull"
        }).catch(err=>{
            console.log(err);
            state.updateStatus=false;
            state.apimessage="Update Learners Failed"
        })
    },
    // end update PretestAssessment


    loadAssessmentList({state},payload){
        axios.get('api/assessmentperUser/'+payload).then(res=>{
            state.assessmentlist=res.data;
        }).catch(err=>{
            console.log(err)
        })
    },  

    loadAssessmentListApprover({state}){
        axios.get('api/assessmentApprover').then(res=>{
            state.assessmentlist=res.data;
        }).catch(err=>{
            console.log(err)
        })
    },  
    
    
    async loadAssessment_id({state},payload){
        await axios.get('api/assessment/'+payload).then(res=>{

            state.pretestAssessment=res.data
            state.pretestAssessment._date=state.pretestAssessment._date
            state.pretestAssessment.syYear=new Date(state.pretestAssessment._date).getFullYear();
        }).catch(err=>console.log(err))
    },

    async loadLearners({state},payload){
        await axios.get('api/learnersassessment/'+payload).then(res=>{
            
            state.pretestLearners=res.data
            console.log(state.pretestLearners)
        }).catch(err=>{
            console.log(err)
        })
    },

    async approvePretest({state}){
        await axios.post('api/AssessmentApproval',state.approvePreTest).then(res=>{
            console.log(res)
        }).catch(err=>{console.log(err)})
    },

    async getAssessmentStatus({state},payload){
        await axios.get('api/AssessmentApproval/'+payload).then(res=>{
            state.assessmentStatus=res.data;
        }).catch(err=>{console.log(err)})
    },

    async savePostAssessment({state}){
        await axios.post('api/savePostAssessment',state.pretestLearners).then(res=>{
            if(res.status='200'){
                this.$toast.success("Save Post Assessment")
            }
        }).catch(err=>{console.log(err)})
    },

    async getassessmentSummary({state}){
        await axios.get('api/assessmentSummary/'+state.pretestAssessment.id).then(res=>{
            state.assessmentSummary=res.data
        }).catch(err=>console.log(err))
    },

    async deleteAssessment({state},payload){
       
        await axios.post('api/deleteassessment',payload).then(res=>{
           return true
        }).catch(err=>{return false})
    },

    async clearValues({state}){
        state.pretestAssessment={}
        state.pretestLearners=[]
        state.assessmentStatus={}
        state.assessmentSummary={}
    },

    async getAssessmentTools({state}){
        await axios.get('api/AssessmentTools').then(res=>{
            state.assess_tools=res.data
        }).catch(err=>{console.log(err)})
    },

    async getAssessmentLevel({state},payload){
        await axios.get('api/getAssessmentLevel/'+payload.assesstool+'/'+payload._type+'/'+payload.value).then(res=>{
            state.assesstoollevel=res.data
        }).catch(err=>{
            console.log(err)
        })
    }
}

const getters = { }

const mutations = make.mutations(state)

export default{
    namespaced:true,
    state,
    mutations,
    actions,
    getters
}