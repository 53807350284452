// Pathify
import { make } from 'vuex-pathify'
import axios from 'axios'

const state={
    brgy:[]
}


const mutations = make.mutations(state)

const getters = make.getters(state)

const actions = {
    // load items
    async brgyload ({ state }) {
      await axios.get('api/brgy').then(res => {
         state.brgy=res.data
        })
        .catch(err => console.log(err))
    },
}

export default{
    namespaced:true,
    state,
    mutations,
    actions,
    getters
}