// Pathify
import axios from "axios";
import { set } from "lodash";
import { make } from "vuex-pathify";

const state = {
  selLearner: [],
  newlearners: {
    lrn: "",
    gname: "",
    surname: "",
    mname: "",
    age: "0",
    gender: "",
    dob: "",
    contact: "",
    brgy_id: "",
    address_others: "",
    emrgcyContactName: "",
    emrgcyContactNo: "",
    method:"POST",
    disabled:false,
  },
  learners: [  ],
  learnerAssessment:{},
  checkLRN:false,
};

const mutations = make.mutations(state);

const actions = {
  async submit({state},payload) {
    console.log(payload)
    await axios
      .post("/api/learners", payload)
      .then((res) => {
        console.log(res);
        this.$toast.success("Successfully Added Learner");
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async load({state}){
    await axios.get("/api/learners").then(res=>{
      state.learners=res.data
    }).catch(err=>console.log(err))
  },

  async update({state},payload){
    await axios.post('/api/learners',payload).then(res=>{

    }).catch(err=>console.log(err))
  },

  async delete(){
    await axios.post('/api/learners',state.selLearner).then(res=>console.log(res)).catch(err=>console.log(err))
  },

  async getLearnersAssessmentRecord({state},payload){
    await axios.get('api/learnersAssessmentRecord/'+payload).then(res=>{
      state.learnerAssessment=res.data;
    }).catch(err=>console.log(err))
  },

  async verifyLRN({state},payload){
    state.checkLRN=false;
    await axios.get('api/learnerslrn/'+payload).then((res)=>{
      
      if(res.data){
        state.checkLRN=true
      }else{
        state.checkLRN=false
      }
    }).catch(err=>console.log(err))
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
