// Utilities
import { make } from 'vuex-pathify'

// Globals
import { IN_BROWSER } from '@/util/globals'
import axios from 'axios'

const state = {
  dark: false,
  ulogin:false,
  users:[],
  drawer: {
    image: 0,
    gradient: 0,
    mini: false,
  },
  gradients: [
    'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
    'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',
    'rgba(244, 67, 54, .8), rgba(244, 67, 54, .8)',
  ],
  images: [
    // 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
    // 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-2.jpg',
    // 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-3.jpg',
    // 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-4.jpg',
  ],
  notifications: [],
  rtl: false,
  userlogin:[],
}

const mutations = make.mutations(state)

const actions = {
  fetch: ({ commit }) => {
    const local = localStorage.getItem('vuetify@user') || '{}'
    const user = JSON.parse(local)

    for (const key in user) {
      commit(key, user[key])
    }

    if (user.dark === undefined) {
      commit('dark', window.matchMedia('(prefers-color-scheme: dark)'))
    }
  },
  update: ({ state }) => {
    if (!IN_BROWSER) return

    localStorage.setItem('vuetify@user', JSON.stringify(state))
  },

  async loginUser({state,dispatch},payload){
    await axios.get('/sanctum/csrf-cookie').then( async res=>{
      await axios.post('login',payload).then(  res=>{
        if(res.status>=200 || res.status<=500)
        {
           state.userlogin=res.data
           state.ulogin=true   
           dispatch('getUsers') 
        }
      }).catch(err=>{console.log(err)})
    })
  },

  async logoutUser({state}){
    await axios.post('logout',state.userlogin.id).catch(err=>{
       state.ulogin=false
    })
  },

  async getUsers({state}){
    await axios.get('api/allusers').then(res=>{
      state.users=res.data;
    }).catch(err=>console.log(err))
  }
  
}

const getters = {
  dark: (state, getters) => {
    return (
      state.dark ||
      getters.gradient.indexOf('255, 255, 255') === -1
    )
  },
  gradient: state => {
    return state.gradients[state.drawer.gradient]
  },
  image: state => {
    return state.drawer.image === '' ? state.drawer.image : state.images[state.drawer.image]
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
