// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import { trailingSlash } from '@/util/helpers'
import {
  layout,
  route,
} from '@/util/routes'


Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    layout('Default', [
     
      route('Dashboard',null,'Dashboard'),
      route('login'),
      // Pages
      route('UserProfile', null, 'components/profile'),

      // Components
      // route('Notifications', null, 'components/notifications'),
      // route('Icons', null, 'components/icons'),
      // route('Typography', null, 'components/typography'),

      // Tables
      route('Learners',null,'learners'),
      route('Learners Assessment',null,'LearnersAssessment'),
      route('Assessment',null,'Assessment'),
      route('Assessment List',null,'Assessmentlist'),
      route('Assessment Overview',null,'AssessmentOverview'),
      
      route('program',null,'program'),
      route('school',null,'school'),
      route('brgy',null,'brgy'),
      route('Assessment Tools',null,'AssessmentTools'),
      //forms
      route('Learner Form',null,'learnerform'),

      // Maps
      route('Google Maps', null, 'maps/google'),
    ]),
  ],
})

router.beforeEach((to, from, next) => {

  return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
  // next({ name: 'login' })
  // router.routes.go('login')

  // if(store.state.user.login==true && to.path=='/'){
  //   console.log("Loggedin")
  // }else{
  //   // console.log(router.getRoutes())
  //   next({
  //     path: 'login',
  //     query: { redirect: to.fullPath }
  //   })
    
  // }

})

export default router
