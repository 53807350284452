// Pathify
import axios from "axios";
import { make } from 'vuex-pathify'


const state={
     schools:[],
     selSchool:[] 
}

const mutations = make.mutations(state)

const actions={
    async loadData({state}){
       await axios.get('api/school').then(res=>{
        state.schools=res.data
      }).catch(err=>{console.log(err)})
    },
    async getSchool({state},payload){
      await axios.get('api/school/'+payload).then(res=>{
        state.selSchool=res.data
      }).catch(err=>console.log(err))
    }
}

export default{
    namespaced:true,
    state,
    mutations,
    actions
}