import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import { sync } from 'vuex-router-sync'
import axios from 'axios'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

Vue.config.productionTip = false

sync(store, router)

Vue.use(VueToast,{
  position:'top'
})

axios.defaults.withCredentials = true
axios.defaults.baseURL= "https://mlms.iciaed.com"
axios.defaults.xsrfCookieName= 'XSRF-TOKEN'
axios.defaults.xsrfHeaderName= 'X-XSRF-TOKEN'
axios.defaults.headers= {'Content-Type': 'application/json'}

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')
