// Pathify
import axios from "axios";
import { make } from 'vuex-pathify'


const state={
     assessmenttools:[] 
}

const mutations = make.mutations(state)

const actions={
    async loadData({state}){
       await axios.get('api/getAssessmentTools').then(res=>{
        state.assessmenttools=res.data
      }).catch(err=>{console.log(err)})
    },
}

export default{
    namespaced:true,
    state,
    mutations,
    actions
}